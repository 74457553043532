/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-14",
    versionChannel: "nightly",
    buildDate: "2023-11-14T00:21:56.078Z",
    commitHash: "b196c8d1bcc2d691c61d8d78b17b5fd60e0234b8",
};
